import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Navigation } from './components/Navigation';
import { Footer } from './components/Footer';
import { ErrorBoundary } from './components/ErrorBoundary';
import { PrivateRoute } from './components/PrivateRoute';
import { PrivateAdminRoute } from './components/admin/PrivateAdminRoute';
import LoadingPage from './components/LoadingPage';

// Lazy load pages
const HomePage = React.lazy(() => import('./pages/HomePage'));
const LoginPage = React.lazy(() => import('./pages/auth/LoginPage'));
const RegisterPage = React.lazy(() => import('./pages/auth/RegisterPage'));
const BusinessLoginPage = React.lazy(() => import('./pages/auth/BusinessLoginPage'));
const BusinessRegisterPage = React.lazy(() => import('./pages/auth/BusinessRegisterPage'));
const BusinessDetailPage = React.lazy(() => import('./pages/business/BusinessDetailPage'));
const DashboardPage = React.lazy(() => import('./pages/dashboard/DashboardPage'));
const FavoritesPage = React.lazy(() => import('./pages/FavoritesPage'));

// Admin pages
const AdminLoginPage = React.lazy(() => import('./pages/admin/LoginPage'));
const AdminDashboardPage = React.lazy(() => import('./pages/admin/DashboardPage'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000,
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

function AppRoutes() {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <Navigation />
      <div className="flex-grow">
        <ErrorBoundary>
          <Suspense fallback={<LoadingPage />}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/login/business" element={<BusinessLoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/register/business" element={<BusinessRegisterPage />} />
              <Route path="/business/:id" element={<BusinessDetailPage />} />
              <Route path="/favorites" element={
                <PrivateRoute>
                  <FavoritesPage />
                </PrivateRoute>
              } />
              <Route path="/dashboard/*" element={
                <PrivateRoute>
                  <DashboardPage />
                </PrivateRoute>
              } />

              {/* Admin routes */}
              <Route path="/admin/login" element={<AdminLoginPage />} />
              <Route path="/admin/dashboard/*" element={
                <PrivateAdminRoute>
                  <AdminDashboardPage />
                </PrivateAdminRoute>
              } />
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </div>
      <Footer />
    </div>
  );
}

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </QueryClientProvider>
  );
}