import React from 'react';
import { Link } from 'react-router-dom';
import { Coffee, Shield, Mail, Phone } from 'lucide-react';

export function Footer() {
  return (
    <footer className="bg-white border-t">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Logo & Description */}
          <div className="col-span-2">
            <div className="flex items-center gap-2">
              <Coffee className="w-6 h-6 text-blue-600" />
              <span className="font-semibold text-gray-900">Coffee Compass</span>
            </div>
            <p className="mt-4 text-gray-600">
              Descubre las mejores cafeterías de Chile. Conectamos amantes del café con locales únicos y auténticos.
            </p>
          </div>

          {/* Links */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">
              Enlaces
            </h3>
            <ul className="mt-4 space-y-2">
              <li>
                <Link to="/" className="text-gray-600 hover:text-gray-900">
                  Inicio
                </Link>
              </li>
              <li>
                <Link to="/register/business" className="text-gray-600 hover:text-gray-900">
                  Registra tu Negocio
                </Link>
              </li>
              <li>
                <Link to="/login" className="text-gray-600 hover:text-gray-900">
                  Iniciar Sesión
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">
              Contacto
            </h3>
            <ul className="mt-4 space-y-2">
              <li className="flex items-center gap-2 text-gray-600">
                <Mail className="w-4 h-4" />
                <span>contacto@coffeecompass.net</span>
              </li>
              <li className="flex items-center gap-2 text-gray-600">
                <Phone className="w-4 h-4" />
                <span>+56 2 2234 5678</span>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="mt-8 pt-8 border-t">
          <div className="text-sm text-gray-500">
            <p className="mb-4">&copy; {new Date().getFullYear()} Coffee Compass. Todos los derechos reservados.</p>
            <p>Proyecto realizado por: {' '}
              <a 
                href="https://koffein.cl" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800"
              >
                Koffein - Agencia SEO Chile
              </a>
              {' '}&amp;{' '}
              <a 
                href="https://danieltoledocofre.info" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800"
              >
                Daniel Toledo Cofré - Analista SEO &amp; Purple Marketer
              </a>
            </p>
          </div>
          
          {/* Admin link - will be removed in production */}
          <Link
            to="/admin/login"
            className="inline-flex items-center gap-2 text-sm text-gray-500 hover:text-gray-900 mt-4"
          >
            <Shield className="w-4 h-4" />
            <span className="sr-only">Admin Access</span>
          </Link>
        </div>
      </div>
    </footer>
  );
}