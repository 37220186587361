import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Coffee, LogIn, LogOut, LayoutDashboard } from 'lucide-react';
import { useAuthStore } from '@/store/authStore';

export function Navigation() {
  const navigate = useNavigate();
  const { user, isAuthenticated, logout } = useAuthStore();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Failed to logout:', error);
    }
  };

  return (
    <nav className="bg-white shadow-sm">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <Link to="/" className="flex items-center gap-2">
            <Coffee className="w-6 h-6 text-blue-600" />
            <span className="font-semibold text-gray-900">Coffee Compass</span>
          </Link>

          <div className="flex items-center gap-4">
            {isAuthenticated ? (
              <div className="flex items-center gap-4">
                {user?.role === 'business' && (
                  <Link
                    to="/dashboard"
                    className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                  >
                    <LayoutDashboard className="w-5 h-5" />
                    <span>Mi Dashboard</span>
                  </Link>
                )}
                <div className="flex items-center gap-2 text-sm text-gray-600">
                  <span>Hola, {user?.name}</span>
                </div>
                <button
                  onClick={handleLogout}
                  className="flex items-center gap-2 px-4 py-2 text-gray-600 hover:text-gray-900"
                >
                  <LogOut className="w-5 h-5" />
                  <span className="hidden sm:inline">Cerrar Sesión</span>
                </button>
              </div>
            ) : (
              <>
                <Link
                  to="/login"
                  className="flex items-center gap-2 px-4 py-2 text-gray-600 hover:text-gray-900"
                >
                  <LogIn className="w-5 h-5" />
                  <span>Iniciar Sesión</span>
                </Link>
                <Link
                  to="/register/business"
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  Registrar mi Negocio
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}