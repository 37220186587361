import { create } from 'zustand';
import { mockUsers } from '@/services/mock/users';
import { sendConfirmationEmail } from '@/services/email';
import type { AuthState, LoginCredentials, RegisterData, User } from '@/types/auth';

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  token: localStorage.getItem('auth_token'),
  isAuthenticated: !!localStorage.getItem('auth_token'),

  login: async (credentials: LoginCredentials) => {
    try {
      const user = mockUsers.find(u => 
        u.email === credentials.email && 
        (credentials.role ? u.role === credentials.role : true)
      );

      if (!user) {
        throw new Error('Credenciales inválidas');
      }

      if (!user.emailConfirmed) {
        throw new Error('Por favor confirma tu correo electrónico antes de iniciar sesión');
      }

      localStorage.setItem('auth_token', 'mock-token');
      localStorage.setItem('user', JSON.stringify(user));

      set({
        user,
        token: 'mock-token',
        isAuthenticated: true,
      });
    } catch (error) {
      console.error('Error en inicio de sesión:', error);
      throw error;
    }
  },

  register: async (data: RegisterData) => {
    try {
      // Verificar si el usuario ya existe
      const existingUser = mockUsers.find(u => u.email === data.email);
      if (existingUser) {
        throw new Error('Ya existe una cuenta con este correo electrónico');
      }

      // Crear nuevo usuario
      const user: User = {
        id: crypto.randomUUID(),
        email: data.email,
        name: data.name,
        businessName: data.businessName,
        favorites: [],
        role: data.role || 'user',
        businessIds: data.role === 'business' ? [crypto.randomUUID()] : undefined,
        likedReviews: [],
        emailConfirmed: false
      };

      // Generar token de confirmación
      const confirmationToken = crypto.randomUUID();

      // Enviar correo de confirmación
      await sendConfirmationEmail(user, confirmationToken);

      // En una app real, guardar el token en la base de datos
      console.log('Token de confirmación:', confirmationToken);

      set({
        user,
        token: null,
        isAuthenticated: false,
      });
    } catch (error) {
      console.error('Error en registro:', error);
      throw error;
    }
  },

  confirmEmail: async (token: string) => {
    try {
      // En una app real, verificar el token con el backend
      const response = await fetch(`${import.meta.env.VITE_API_URL}/auth/confirm-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      if (!response.ok) {
        throw new Error('Token de confirmación inválido o expirado');
      }

      const { user } = await response.json();

      localStorage.setItem('auth_token', 'mock-token');
      localStorage.setItem('user', JSON.stringify(user));

      set({
        user,
        token: 'mock-token',
        isAuthenticated: true,
      });
    } catch (error) {
      console.error('Error en confirmación de correo:', error);
      throw error;
    }
  },

  logout: async () => {
    try {
      localStorage.removeItem('auth_token');
      localStorage.removeItem('user');

      set({
        user: null,
        token: null,
        isAuthenticated: false,
      });
    } catch (error) {
      console.error('Error en cierre de sesión:', error);
      throw error;
    }
  },

  toggleFavorite: async (businessId: string) => {
    set((state) => {
      if (!state.user) return state;

      const favorites = state.user.favorites.includes(businessId)
        ? state.user.favorites.filter(id => id !== businessId)
        : [...state.user.favorites, businessId];

      const updatedUser = {
        ...state.user,
        favorites,
      };

      localStorage.setItem('user', JSON.stringify(updatedUser));

      return {
        user: updatedUser,
      };
    });
  },
}));