import { create } from 'zustand';

interface AdminState {
  isAuthenticated: boolean;
  token: string | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

export const useAdminStore = create<AdminState>((set) => ({
  isAuthenticated: !!localStorage.getItem('admin_token'),
  token: localStorage.getItem('admin_token'),

  login: async (email: string, password: string) => {
    // Mock login - replace with actual API call
    if (email === 'admin@localbiz.com' && password === 'admin123') {
      const token = 'mock_admin_token';
      localStorage.setItem('admin_token', token);
      set({ isAuthenticated: true, token });
    } else {
      throw new Error('Invalid credentials');
    }
  },

  logout: () => {
    localStorage.removeItem('admin_token');
    set({ isAuthenticated: false, token: null });
  },
}));