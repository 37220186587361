import type { User } from '@/types/auth';

const API_URL = import.meta.env.VITE_API_URL;

interface EmailTemplate {
  to: string;
  subject: string;
  template: string;
  data: Record<string, any>;
}

export async function sendConfirmationEmail(user: User, token: string): Promise<void> {
  const template: EmailTemplate = {
    to: user.email,
    subject: user.role === 'business' 
      ? 'Confirma tu cuenta de negocio en Coffee Compass'
      : 'Confirma tu cuenta en Coffee Compass',
    template: user.role === 'business' ? 'business-confirmation' : 'user-confirmation',
    data: {
      name: user.name,
      confirmationUrl: `${window.location.origin}/confirm-email?token=${token}`,
      businessName: user.role === 'business' ? user.businessName : undefined
    }
  };

  try {
    const response = await fetch('/.netlify/functions/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(template),
    });

    if (!response.ok) {
      const error = await response.text();
      throw new Error(`Failed to send confirmation email: ${error}`);
    }
  } catch (error) {
    console.error('Error sending confirmation email:', error);
    throw new Error('No se pudo enviar el correo de confirmación. Por favor intenta nuevamente.');
  }
}