import type { User } from '@/types/auth';

export const mockUsers: User[] = [
  // Business Users
  {
    id: 'b1',
    email: 'barrio.italia@demo.com',
    name: 'Café Artesanal Barrio Italia',
    businessIds: ['1'],
    favorites: [],
    role: 'business',
    avatar: 'https://images.unsplash.com/photo-1501339847302-ac426a4a7cbb'
  },
  {
    id: 'b2',
    email: 'providencia@demo.com',
    name: 'Café Providencia Specialty',
    businessIds: ['2'],
    favorites: [],
    role: 'business',
    avatar: 'https://images.unsplash.com/photo-1495474472287-4d71bcdd2085'
  },
  {
    id: 'b3',
    email: 'puerto.cultura@demo.com',
    name: 'Café Puerto Cultura',
    businessIds: ['3'],
    favorites: [],
    role: 'business',
    avatar: 'https://images.unsplash.com/photo-1554118811-1e0d58224f24'
  },
  {
    id: 'b4',
    email: 'biobio@demo.com',
    name: 'Café Biobío Ancestral',
    businessIds: ['4'],
    favorites: [],
    role: 'business',
    avatar: 'https://images.unsplash.com/photo-1554118811-1e0d58224f24'
  },
  // Regular Users
  {
    id: 'u1',
    email: 'cliente@demo.com',
    name: 'Cliente Demo',
    favorites: [],
    role: 'user',
    avatar: 'https://ui-avatars.com/api/?name=Cliente+Demo'
  }
];