import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAdminStore } from '@/store/adminStore';

interface PrivateAdminRouteProps {
  children: React.ReactNode;
}

export function PrivateAdminRoute({ children }: PrivateAdminRouteProps) {
  const location = useLocation();
  const { isAuthenticated } = useAdminStore();

  if (!isAuthenticated) {
    return <Navigate to="/admin/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
}